import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class FaqService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/faq`;

      }

      paginate(data={},index = null) {
            let url = `${this.#api}`;
            if (index != null)
                  url = `${url}?page=${index}`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
      }
      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }
      sort( data) {
            let url = `${this.#api}/sort`;
            return apiService.post(url, data);
      }


      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
